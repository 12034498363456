// import { configureStore } from '@reduxjs/toolkit';
import containerReducer from './reducer';
import { createStore } from "redux";

// export default configureStore({
//   reducer: {
//     counter: containerReducer,
//   },
// });

const store = createStore(containerReducer);

export default store;