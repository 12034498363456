
import Loadable from "react-loadable";
import Loading from "./Loading";

const ProjectBuilderPage = Loadable({
    loader: () => import("./shed-home"),
    loading: Loading
});


const CustomerPage = Loadable({
    loader: () => import("./custom-client-home"),
    loading: Loading
});

const ProjectListPage = Loadable({
    loader: () => import("./project-list"),
    loading: Loading
});


export { ProjectBuilderPage, CustomerPage, ProjectListPage };