import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
    width: 100,
    height: 100,
    sideMenuOpen: false,
    loginModal: true,
    forceFlag: false,
    clientMenu: 0,
    json_data: {},
    project_data: {},
    widthSizes: [],
    heightSizes: [],
    depthSizes: [],

    customLoader: "false",
    projectName: "Customer Shed",
    animationFlag: false,
    preloaderShow: true,
    selectedParentIDs: {
      typelist: [],
      id: []
    }
  },

  reducers: {
    increment: state => {
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },

    decrement: state => {
      state.value -= 1;
    },

    setSizes: (state, action) =>{
      state.width = action.payload[0]
      state.height = action.payload[1]
    },

    toggleSideMenu: (state) =>{
      state.sideMenuOpen = !state.sideMenuOpen
    },

    toggleLoginModal: (state, action) =>{
      state.loginModal = action.payload
      // state.loginModal = !state.loginModal
    },

    openCustomLoader: (state, action) =>{
      state.customLoader = action.payload
    },

    togglePreloader: (state, action) =>{
      state.preloaderShow = action.payload
    },

    setForceFlag: (state, action) =>{
      state.forceFlag = action.payload     
    },

    setAnimationFlag: (state, action) =>{
      state.animationFlag = action.payload     
    },
// Client pages reducer
    selectClientMenu: (state, action) =>{
      state.clientMenu = action.payload
    },
    setJsonData: (state, action) =>{
      state.json_data = action.payload
    },
    setProjectName: (state, action) =>{
      state.projectName = action.payload
    },
    setSelectedParentID: (state, action) => {
      // action is {id: xxx, typelist: yyy}
      // state.selectedParentIDs = action.payload
      var existance = false
      state.selectedParentIDs.typelist.map((typelist, index) => {
        if (typelist === action.payload.typelist) {
          state.selectedParentIDs.id[index] = action.payload.id;
          existance = true
        }
      })
      if (!existance) {
        state.selectedParentIDs.typelist.push(action.payload.typelist);
        state.selectedParentIDs.id.push(action.payload.id)
      }
    }
  },
});

export const { increment,openCustomLoader, decrement, toggleSideMenu, setSizes, toggleLoginModal, togglePreloader, setForceFlag, selectClientMenu, setJsonData, setProjectName, setAnimationFlag, setSelectedParentID} = slice.actions;

export const getSizes = state => [state.sizes.width, state.sizes.height];

export default slice.reducer;
