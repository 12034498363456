
import Loadable from "react-loadable";
import Loading from "./Loading";

const HeaderComp = Loadable({
    loader: () => import("./Header"),
    loading: Loading
});

const CanvasComp = Loadable({
    loader: () => import("./CanvasComp"),
    loading: Loading
});

const ClientSideBar = Loadable({
    loader: () => import("./CustomClientSideBar"),
    loading: Loading
});


const ClientSaveBar = Loadable({
    loader: () => import("./CustomSaveBar"),
    loading: Loading
});

const ClientPanel = Loadable({
    loader: () => import("./CustomClientPanel"),
    loading: Loading
});

const CustomLoader = Loadable({
    loader: () => import("./CustomLoader"),
    loading: Loading
});




export { HeaderComp, ClientPanel, CanvasComp, ClientSaveBar, ClientSideBar, CustomLoader };